import { spawn } from "child_process";
import { Link, useHistory } from "react-router-dom";
import { CRYPTO_JS_SECRET, TABS_LABELS } from "../../utils/constants";
// import RecipientInfoButton from './RecipientInfoButton';
import CryptoJS from "crypto-js/aes";

const RenderFieldValue = ({ configKey, packageItem, config }: any) => {
  // const handleTogglePopover = (popoverId: string) => {
  //   if (isPopoverOpen === popoverId) {
  //     setIsPopoverOpen("");
  //   } else {
  //     setIsPopoverOpen(popoverId);
  //   }
  // };
  const history = useHistory();

  if (configKey === "statusId") {
    return config.render(packageItem[configKey]);
  }

  if (configKey === "validated") {
    return config.render(packageItem[configKey]);
  }

  if (configKey === "ticketFulfilled") {
    return config.render(packageItem[configKey]);
  }

  if (configKey === "recipient") {
    // const popoverId = packageItem[configKey].recipientPhone
    //   ? packageItem[configKey].recipientPhone.replace(/\D/g, "").slice(1)
    //   : null;
    // return (
    //   <RecipientInfoButton
    //     popoverId={popoverId}
    //     config={config}
    //     handleTogglePopover={handleTogglePopover}
    //     isPopoverOpen={isPopoverOpen}
    //     item={packageItem}
    //     itemKey={configKey}
    //     setCurrentTab={setCurrentTab}
    //     setIsPopoverOpen={setIsPopoverOpen}
    //     setFilter={setFilter}
    //     setFilterState={setFilterState}
    //   />
    // );

    return packageItem[configKey].recipientName;
  }

  if (configKey === "client") {
    return (
      <button
        className="btn-cell"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          // setCurrentTab(TABS_LABELS.VIEW_ALL);
          // setFilter((state: any) => {
          //   return { ...state, [configKey]: packageItem[configKey] };
          // });
          // setFilterState((state: any) => {
          //   return { ...state, [configKey]: packageItem[configKey] };
          // });
        }}
      >
        {packageItem[configKey]}
      </button>
    );
  }

  if (configKey === "invoiceId") {
    return (
      <button
        style={{
          border: "none",
          background: "none",
          color: "#337ab7",
          padding: "0",
          textDecoration: "underline",
        }}
        onClick={() => {
          const encryptedInvoiceId = CryptoJS.encrypt(
            `${packageItem[configKey]}`,
            CRYPTO_JS_SECRET,
          ).toString();

          const encryptedInvoiceIdWithReplacedSlashes = encryptedInvoiceId.replace(
            /\//g,
            "HjsOwpeSBqdLafaFsjhHfd73sb7kdheYTsd61",
          );

          history.push(`/package-info/${encryptedInvoiceIdWithReplacedSlashes}`);
        }}
      >
        {packageItem[configKey]}
      </button>
    );
  }
  return <>{packageItem[configKey] ? packageItem[configKey] : null}</>;
};

export default RenderFieldValue;
