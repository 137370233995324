import moment from 'moment';
import React from 'react';
import { PopoverBody, Spinner, UncontrolledPopover } from 'reactstrap';

export interface IFlightInfo {
  departureAirport?: string;
  departureScheduleTime?: string;
  departureActualTime?: string;
  departureTerminal?: string;
  departureGate?: string;
  arrivalAirport?: string;
  arrivalScheduleTime?: string;
  arrivalActualTime?: string;
  arrivalTerminal?: string;
  arrivalGate?: string;
}

const FlightInfoPopover = ({
  parsedCardDetails,
  config,
  cartInfoId,
  cardGroup,
}: {
  parsedCardDetails: any;
  config: any;
  cartInfoId: any;
  cardGroup: any;
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState('');
  const [isPopoverLoading, setIsPopoverLoading] = React.useState(false);
  const [flightInfo, setFlightInfo] = React.useState<IFlightInfo | null>(null);
  const [flightInfoResSuccess, setFlightInfoResSuccess] = React.useState(true);

  const handleTogglePopover = (popoverId: string) => {
    if (isPopoverOpen === popoverId) {
      setIsPopoverOpen('');
    } else {
      setIsPopoverOpen(popoverId);
    }
  };
  
  const handleButtonClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsPopoverLoading(true);
    
    if (!process.env.REACT_APP_FLIGHT_API_KEY) {
      setFlightInfoResSuccess(false);
      setIsPopoverLoading(false);
      return;
    }
    
    let flightInfoData = [];
    
    try {
      const flightInfoDataRequest = await fetch(
        `https://api.flightapi.io/airline/${process.env.REACT_APP_FLIGHT_API_KEY}?num=${
          parsedCardDetails?.departureFlightNumber?.match(/[0-9]+/) ||
          parsedCardDetails?.arrivalFlightNumber?.match(/[0-9]+/) || 
          parsedCardDetails?.flightNumber?.match(/[0-9]+/) ||
          parsedCardDetails?.departureFlightAirline?.match(/[aA-zZ]+/) ||
          parsedCardDetails?.arrivalFlightAirline?.match(/[aA-zZ]+/)
        }&name=${
          parsedCardDetails?.departureFlightAirline?.match(/[aA-zZ]+/) ||
          parsedCardDetails?.arrivalFlightAirline?.match(/[aA-zZ]+/) ||
          parsedCardDetails?.departureFlightNumber?.match(/[0-9]+/) ||
          parsedCardDetails?.arrivalFlightNumber?.match(/[0-9]+/) || 
          parsedCardDetails?.flightNumber?.match(/[0-9]+/)
        }&date=${moment(parsedCardDetails.departureFlightDate).format("YYYYMMDD")}`,
      );
      flightInfoData = await flightInfoDataRequest.json();
    } catch (error) {
      setFlightInfoResSuccess(false);
      setIsPopoverLoading(false);
      return;
    }

    if (!flightInfoData.length) {
      setFlightInfoResSuccess(false);
      setIsPopoverLoading(false);
      return;
    }
    
    function splitFlightInfoData(data: Record<string, any>[]) {
      const departureObj = data.find(item => item.departure);
      const arrivalObj = data.find(item => item.arrival);
  
      return {
        departure: departureObj ? departureObj.departure[0] : null,
        arrival: arrivalObj ? arrivalObj.arrival[0] : null
      };
    }
  
    
    const {departure, arrival} = splitFlightInfoData(flightInfoData);
    
    const formattedFlightInfo: any = {
      departureAirport: departure?.["Airport:"] ? departure["Airport:"] : '',
      departureScheduleTime: departure?.["Scheduled Time:"] ? departure["Scheduled Time:"] : '',
      departureActualTime: departure?.["Takeoff Time:"] ? departure["Takeoff Time:"] : '',
      departureTerminal: departure?.["Terminal - Gate:"] ?  departure["Terminal - Gate:"]?.split(" - ")[0] : '',
      departureGate: departure?.["Terminal - Gate:"] ? departure["Terminal - Gate:"]?.split(" - ")[1] : '',
      
      arrivalAirport: arrival?.["Airport:"] ? arrival["Airport:"] : '',
      arrivalScheduleTime: arrival?.["Scheduled Time:"] ? arrival["Scheduled Time:"] : '',
      arrivalActualTime: arrival?.["Estimated Time:"] ? arrival["Estimated Time:"] : '',
      arrivalTerminal: arrival?.["Terminal - Gate:"] ?  arrival["Terminal - Gate:"]?.split(" - ")[0] : '',
      arrivalGate: arrival?.["Terminal - Gate:"] ? arrival["Terminal - Gate:"]?.split(" - ")[1] : '',
    };

    setFlightInfo(formattedFlightInfo);
    setIsPopoverLoading(false);
  };

  return (
    <>
      <button
        style={{ border: '0', background: 'none', padding: '0' }}
        onClick={handleButtonClick}
        className="btn-cell"
        id={`id-${config.name}-${cartInfoId}`}
      >
        <i className="fa fa-solid fa-circle-info ml-2"></i>
      </button>

      <UncontrolledPopover 
        placement="right"
        isOpen={isPopoverOpen === `id-${config.name}-${cartInfoId}`} 
        target={`id-${config.name}-${cartInfoId}`} 
        toggle={() => {
          setFlightInfo(null);
          handleTogglePopover(`id-${config.name}-${cartInfoId}`);
      }}>
        <PopoverBody onClick={(e) => e.stopPropagation()}>
        {isPopoverLoading && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spinner animation="border" variant="info" size="lg" />
          </div>
        )}
        {flightInfoResSuccess && !isPopoverLoading && (
          <div data-js={`id-${config.name}-${cartInfoId}`}>
            <div className="text-right">
              <i
                className="fas fa-window-close"
                onClick={(e) => {
                  e.stopPropagation();
                  handleTogglePopover(`id-${config.name}-${cartInfoId}`);
                }}
                style={{ cursor: 'pointer' }}
              />
            </div>
            {flightInfo &&
              <div>
                {(cardGroup === 'Airport Transfer Arrival' || (cardGroup === 'Airport Transfer' && parsedCardDetails?.transferId === '1')) &&
                  <>
                    <strong>Arrival Airport:</strong>&nbsp;&nbsp;<span>{flightInfo?.arrivalAirport}</span><br />
                    <strong>Scheduled Arrival:</strong>&nbsp;&nbsp;<span>{flightInfo?.arrivalScheduleTime}</span><br />
                    <strong>Actual Arrival:</strong>&nbsp;&nbsp;<span>{flightInfo?.arrivalActualTime}</span><br />
                    <strong>Arrival Terminal:</strong>&nbsp;&nbsp;<span>{flightInfo?.arrivalTerminal}</span><br />
                    <strong>Arrival Gate:</strong>&nbsp;&nbsp;<span>{flightInfo?.arrivalGate}</span><br />
                  </>
                }
                
                {(cardGroup === 'Airport Transfer Departure' || (cardGroup === 'Airport Transfer' && parsedCardDetails?.transferId === '2')) && 
                  <>
                    <strong>Departure Airport:</strong>&nbsp;&nbsp;<span>{flightInfo?.departureAirport}</span><br />
                    <strong>Scheduled Departure:</strong>&nbsp;&nbsp;<span>{flightInfo?.departureScheduleTime}</span><br />
                    <strong>Actual Departure:</strong>&nbsp;&nbsp;<span>{flightInfo?.departureActualTime}</span><br />
                    <strong>Departure Terminal:</strong>&nbsp;&nbsp;<span>{flightInfo?.departureTerminal}</span><br />
                    <strong>Departure Gate:</strong>&nbsp;&nbsp;<span>{flightInfo?.departureGate}</span><br />
                  </>
                }
              </div>
            }
          </div>
        )}
        {!flightInfoResSuccess && !isPopoverLoading && (
          <span>Info Not Found</span>
        )}
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default FlightInfoPopover;