import linkloadIcon from "../../assets/img/icons/cards/link-icon.svg";
import qrCodeIcon from "../../assets/img/icons/cards/qr-code-icon.svg";

import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { successMsg } from "../../cache/vars";

const UploadedUrls = ({
  ticket,
  index,
}: any) => {
  return (
    <div
      className="d-flex justify-content-between"
      key={index}
    >
      <div className="d-flex align-items-center" style={{ width: "100%" }}>
        <button 
          type="button"
          style={{ cursor: 'pointer' }}
          className="btn btn-link p-0"
          onClick={(e) => {
            e.preventDefault();

            navigator.clipboard.writeText(ticket.eTicketLink);
            successMsg('Copied to clickboard');
          }}
        >
          {ticket.isQrCode 
            ? <img src={qrCodeIcon} alt="qr-code-icon" style={{ cursor: 'pointer' }}/>
            : <img src={linkloadIcon} alt="link-icon" style={{ cursor: 'pointer' }}/>
          }
        </button>

        <a className="btn btn-link pl-0 text-start" href={ticket.eTicketLink} target="_blank" rel="noreferrer" 
          style={{ overflowWrap: 'anywhere', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {ticket.isQrCode 
              ? 'Open QR Code'
              : 'Open URL'
          }
        </a>
      </div>
    </div>
    );
    }

export default UploadedUrls;
