import { gql } from "@apollo/client";

export const USER_SIGN_UP = gql`
  mutation userRegister($registerInput: RegisterInput!) {
    userRegister(registerInput: $registerInput) {
      user {
        userId
        email
        role
        createdAt
      }
    }
  }
`;

export const AUTH = gql`
  mutation logIn($authInput: AuthInput!) {
    logIn(authInput: $authInput) {
      user {
        userId
        email
        role
        createdAt
        isEmailConfirmed
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query getProfile {
    getProfile {
      email
      role
      createdAt
      userId
      isEmailConfirmed
    }
  }
`;

export const GET_BROKER_PROFILE = gql`
  query getProfile {
    getProfile {
      email
      role
      createdAt
      userId
      brokerId
      isEmailConfirmed
    }
  }
`;

export const GOOGLE_AUTH = gql`
  mutation googleLogin($token: String!) {
    googleLogin(token: $token) {
      user {
        userId
        email
        role
        createdAt
        isEmailConfirmed
      }
    }
  }
`;

export const GOOGLE_SIGN_UP = gql`
  mutation googleSignup($token: String!) {
    googleSignup(token: $token) {
      user {
        userId
        email
        role
        createdAt
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token)
  }
`;

export const CONFIRM_EMAIL = gql`
  mutation confirmEmail($token: String!) {
    confirmEmail(token: $token)
  }
`;

export const REQUEST_CONFIRM_EMAIL_LINK = gql`
  query requestConfirmEmailLink($email: String!) {
    requestConfirmEmailLink(email: $email)
  }
`;
