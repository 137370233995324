import "./provideMissingInfo.scss";

const ProvideMissingInfo = () => {
  return (
    <div className="d-flex align-items-center provide-missing-info-label-container">
      <div className="exclamation-circle"></div>
      <span className="info-note">
        All missing information must be provided for full package details to be ready.
      </span>
    </div>
  );
};

export default ProvideMissingInfo;
