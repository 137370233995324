import { Navbar, Container, Button } from "reactstrap";
import { TABS } from "../../utils/constants";
import "./Navbar.scss";

const NavbarTabs = ({ currentTab, currentSection, handleCurrentTab }: any) => {
  return (
    <Navbar className="Navbar navbar-top navbar-expand navbar-dark py-0">
      <Container fluid className="Navbar__btnsContainer">
        {TABS[currentSection as keyof typeof TABS].map((title, id) => {
          return (
            <Button
              key={id}
              color="transparent"
              type="button"
              className="tabBtn text-nowrap text-uppercase mx-0 px-4 py-2 h-100 rounded-0"
              active={currentTab === title}
              onClick={(e) => handleCurrentTab(title)}
            >
              {title}
            </Button>
          );
        })}
      </Container>
    </Navbar>
  );
};

export default NavbarTabs;
