import { Input } from "antd";
import { Button, Col, Form, FormGroup, Row } from "reactstrap";
import "./dashboard.scss";

const SearchByEventNameInput = ({ getClientPackages, setEventNameSearch }: any) => {
  return (
    <Row className="p-0 ml-0">
      <Col sm="8" className="p-0">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            getClientPackages();
          }}
        >
          <FormGroup className="d-flex m-0">
            <Input
              className="mr-4 p-1 w-100 rounded"
              placeholder="Search by Event Name"
              onChange={(e) => {
                setEventNameSearch(e.target.value);
              }}
              allowClear
            />
            <Button
              className="btn-round btn-icon d-flex align-items-center p-2"
              color="primary"
              size="sm"
              type="submit"
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-search ml-1" />{" "}
              </span>
              <span className="btn-inner--text">Search</span>
            </Button>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  );
};

export default SearchByEventNameInput;
