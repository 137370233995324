import { useApolloClient } from "@apollo/client";
import { Formik } from "formik";
import { gapi } from "gapi-script";
import React from "react";
import { GoogleLogin } from "react-google-login";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Row,
} from "reactstrap";
import { errorMsg } from "../../cache/vars";
import { useRoleContext } from "../../Navigator";
import { AUTH, GOOGLE_AUTH } from "../../queries/auth";
import * as Yup from "yup";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

const LoginPage: React.FC = () => {
  const { setRole } = useRoleContext();

  const history = useHistory();
  const client = useApolloClient();

  React.useEffect(() => {
    const initClient = () => {
      gapi.auth2.init({
        clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  const onSuccess = async (res: any) => {
    const authResponse = await client.query({
      query: GOOGLE_AUTH,
      variables: { token: res.tokenId },
      fetchPolicy: "no-cache",
    });

    const jwt = document.cookie.match(/jwt=[^\s]+/);
    const jwtValue = jwt && jwt[0];

    if (
      (authResponse.data?.googleLogin.user.role === "RECIPIENT" ||
        authResponse.data?.googleLogin.user.role === "BROKER") &&
      jwtValue
    ) {
      setRole(authResponse.data?.googleLogin.user.role);

      if (authResponse.data?.googleLogin.user.role === "RECIPIENT") {
        history.push("/dashboard");
      }

      if (authResponse.data?.googleLogin.user.role === "BROKER") {
        history.push("/broker-dashboard");
      }
    }
  };

  const onFailure = (err: any) => {
    console.error("failed:", err);
    errorMsg("Couldn't Log In");
  };

  return (
    <Container className="mt-8 pb-5">
      <Row className="justify-content-center">
        <Col lg="5" md="7">
          <Card className="bg-secondary border-0 mb-0">
            <CardBody className="px-lg-5 py-lg-5">
              <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={async (userInfo) => {
                  const authResponse = await client.query({
                    query: AUTH,
                    variables: { authInput: userInfo },
                    fetchPolicy: "no-cache",
                  });

                  const jwt = document.cookie.match(/jwt=[^\s]+/);
                  const jwtValue = jwt && jwt[0];

                  if (
                    (authResponse.data?.logIn.user.role === "RECIPIENT" ||
                      authResponse.data?.logIn.user.role === "BROKER") &&
                    jwtValue
                  ) {
                    setRole(authResponse.data?.logIn.user.role);
  
                    if (
                      authResponse.data?.logIn.user.role === "RECIPIENT" &&
                      !authResponse.data?.logIn.user.isEmailConfirmed
                    ) {
                      history.push("/email-not-confirmed");
                    }

                    if (
                      authResponse.data?.logIn.user.role === "RECIPIENT" &&
                      authResponse.data?.logIn.user.isEmailConfirmed
                    ) {
                      history.push("/dashboard");
                    }

                    if (authResponse.data?.logIn.user.role === "BROKER") {
                      history.push("/broker-dashboard");
                    }
                  }
                }}
                validationSchema={VALIDATION_SCHEMA}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <Form role="form" onSubmit={handleSubmit}>
                      <FormGroup>
                        <Input
                          placeholder="Email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="email"
                          type="text"
                          value={values.email}
                          invalid={!!errors.email && touched.email}
                        />
                        {errors.email && touched.email ? (
                          <FormFeedback>Field should not be empty</FormFeedback>
                        ) : (
                          <FormText>Enter email</FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          placeholder="Password"
                          type="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="password"
                          value={values.password}
                          invalid={!!errors.password && touched.password}
                        />
                        {errors.password && touched.password ? (
                          <FormFeedback>Field should not be empty</FormFeedback>
                        ) : (
                          <FormText>Enter password</FormText>
                        )}
                      </FormGroup>
                      <div className="text-center d-flex flex-column">
                        <Button className="my-4" color="info" type="submit">
                          Log In
                        </Button>
                        <GoogleLogin
                          clientId={clientId}
                          buttonText="Log In with Google"
                          onSuccess={onSuccess}
                          onFailure={onFailure}
                          cookiePolicy={"single_host_origin"}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6" className="text-left">
              <Link to="/forgot-password" className="text-primary">
                <small>Forgot password?</small>
              </Link>
            </Col>
            <Col className="text-right" xs="6">
              <Link to="/register" className="text-primary">
                <small>Create new account</small>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
