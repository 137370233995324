import React from "react";
import routes from "../../Router";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { GET_BROKER_PROFILE, GET_PROFILE } from "../../queries/auth";
import { checkToken, getRole, isEmailConfirmed } from "../../utils/services";
import Footer from "../../components/Footer/Footer";

export const CommonLayout = React.memo(({ children }: any) => {
  const client = useApolloClient();

  const [sidenavOpen, setSidenavOpen] = React.useState(true);

  const location = useLocation();
  const mainContentRef = React.useRef(null);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.add("g-sidenav-hidden");
  }, [location]);

  React.useEffect(() => {
    if (checkToken()) {
      if (getRole() === "RECIPIENT") {
        client.query({
          query: GET_PROFILE,
          fetchPolicy: "cache-first",
        });
      }

      if (getRole() === "BROKER") {
        client.query({
          query: GET_BROKER_PROFILE,
          fetchPolicy: "cache-first",
        });
      }
    }
  }, []);

  const toggleSidenav = () => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  return (
    <>
      {checkToken() &&
      isEmailConfirmed() &&
      children.props.match?.path !== "/package-info/:invoiceSecret" ? (
        <Sidebar
          routes={routes}
          sidenavOpen={sidenavOpen}
          toggleSidenav={toggleSidenav}
          logo={{
            innerLink: "/",
            imgSrc: require("../../assets/img/brand/argon-react.png"),
            imgAlt: "...",
          }}
        />
      ) : null}
      <div
        className="main-content"
        ref={mainContentRef}
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        <Header />
        <div style={{ flex: "1", position: "relative" }}>
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
});
