import React, { useEffect, useState } from 'react';
import GetInTouch from '../../pages/PackageInfo/GetInTouch';
import './footer.scss';

const Footer = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 575);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 575);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!isMobileView) {
    return null;
  }

  return (
    <footer className="footer-container">
      <GetInTouch />
    </footer>
  );
};

export default Footer;