import React, { useState } from 'react';
import { Dropdown, Input, Menu } from 'antd';
import './airlinesDropdown.scss';
import { airlines } from './airlines';

interface AirlinesDropdownProps {
  className?: string;
  placeholder?: string;
  name?: string;
  value: string;
  onSelect: (value: string) => void;
}

export const AirlinesDropdown: React.FC<AirlinesDropdownProps> = ({ className, placeholder, name, value, onSelect }) => {
  const [filteredAirlines, setFilteredAirlines] = useState(airlines);
  const [open, setOpen] = useState(false);

  const handleSearch = (searchValue: string) => {
    if (airlines) {
      setFilteredAirlines(
        airlines.filter(
          (airline) =>
            airline.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            airline.code.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
  };

  const handleMenuClick = (e: any) => {
    const selectedAirline = airlines.find((airline) => airline.code === e.key);
    if (selectedAirline) {
      onSelect(selectedAirline.code);
    }
    setOpen(false);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {filteredAirlines.map((airline) => (
        <Menu.Item key={airline.code}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {`${airline.name} (${airline.code})`}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      menu={{ items: filteredAirlines.map((airline) => ({ key: airline.code, label: `${airline.name} (${airline.code})` })), onClick: handleMenuClick }}
      trigger={['click']}
      open={open}
      onOpenChange={setOpen}
      dropdownRender={(menu) => (
        <div style={{ maxHeight: '200px', overflowY: 'auto', border: '1px solid rgba(94, 114, 228, 0.1)', boxShadow: '0 3px 9px rgba(50, 50, 9, 0), 3px 4px 8px rgba(94, 114, 228, 0.1)' }}>
          {menu}
        </div>
      )}
      getPopupContainer={(trigger: any) => trigger.parentNode}
    >
      <Input
        className={className}
        placeholder={placeholder}
        name={name}
        value={value}
        style={{ height: '46px' }}
        onChange={(e) => {
          onSelect(e.target.value);
          handleSearch(e.target.value);
          setOpen(true);
        }}
        onClick={() => setOpen(true)}
        autoComplete='off'
      />
    </Dropdown>
  );
};
