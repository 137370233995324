import moment from "moment";
import React from "react";
import { useTimer } from "react-timer-hook";

const Timer = () => {
  const expiryTimestamp = moment().add(30, "minutes").toDate();

  useTimer({
    expiryTimestamp,
    onExpire: () => {
      if (window.confirm("Some info might have changed, please click OK to reload the page")) {
        window.location.reload();
      }
    },
  });

  return null;
};

export default Timer;
