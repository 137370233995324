const TableHead = ({
  tableConfig,
  sortBy,
  sortDirection,
  handleSorting,
  filterState,
  setFilterState,
  handleApplyFilters,
}: any) => {
  return (
    <thead className="thead-light text-center">
      <tr>
        {Object.entries(tableConfig).map(([key, config]: any, id) => (
          <th key={id} onClick={() => handleSorting(config.key)}>
            {tableConfig[key].colTitle}&nbsp;
            {sortBy && sortBy === key ? (
              sortDirection === "ASC" ? (
                <span>&uarr;</span>
              ) : (
                <span>&darr;</span>
              )
            ) : (
              <>
                <span>&uarr;</span>
                <span>&darr;</span>
              </>
            )}
          </th>
        ))}
      </tr>
      <tr>
        {Object.entries(tableConfig).map(([key, config]: any, id) => (
          <th key={id} className="pl-2 pr-2 pt-1 pb-1">
            <>
              <i className="fas fa-filter mr-1" />
              <input
                style={{ width: "85%", appearance: "auto" }}
                value={(filterState[config.key] && filterState[config.key]) || ""}
                type={config.type}
                onChange={(e) => {
                  setFilterState((state: any) => {
                    if (e.target.value === "") {
                      delete state[config.key];

                      return { ...state };
                    }

                    return {
                      ...state,
                      [config.key]:
                        key === "saleDate" || key === "eventDate" || key === "shipBy"
                          ? e.target.value
                          : config.type === "number"
                          ? Number.parseFloat(e.target.value)
                          : e.target.value.toLowerCase(),
                    };
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleApplyFilters();
                  }
                }}
              />
            </>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
