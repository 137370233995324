import { useState } from "react";
import { Container } from "reactstrap";
import mailSvg from "../../assets/img/icons/common/mail.svg";

import "./getInTouch.scss";
import GetHelpModal from "../../components/Modal/GetHelpModal";

const GetInTouch = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center responsive-container">
      <div>
        <span className="got-question-span text-white text-center pl-3 pr-3">Need some help or got a question?</span>
      </div>
      <div className="d-flex align-items-center mt-3 mb-4">
        <a
          className="outline-button d-flex align-items-center"
          onClick={toggleModal}
          style={{ cursor: 'pointer' }}
        >
          <img src={mailSvg} alt="mail icon" className="mr-2" style={{ width: '24px', height: '24px' }} />
          <span className="getInTouch-span">Get in touch</span>
        </a>
      </div>

      <GetHelpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

      <div className="d-flex justify-content-center mt-3">
        <span className="rights-text">© 2024 ElevateVIP, LLC. All rights reserved.</span>
      </div>
    </Container>
  );
};

export default GetInTouch;
