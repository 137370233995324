import { CardFooter, PaginationItem, PaginationLink, Pagination } from 'reactstrap';
import { paginationComponentType } from '../../utils/interfaces';
import { usePagination } from '../../utils/services';
import ItemsPerPageSelect from './ItemsPerPageSelect';
import PaginationRange from './PaginationRange';

const PaginationComponent = ({
  page,
  setPage,
  totalPageCount,
  totalPackagesCount,
  setItemsPerPage,
  itemsPerPage,
}: paginationComponentType) => {
  const paginationRange = usePagination({
    currentPage: page,
    totalCount: totalPackagesCount,
    siblingCount: 2,
    pageSize: itemsPerPage,
    totalPageCount: totalPageCount,
  }) as [];

  if (page === 0 || paginationRange ? paginationRange.length < 2 : null) {
    return null;
  }

  return (
    <CardFooter className="py-4 d-flex">
      <ItemsPerPageSelect
        setItemsPerPage={setItemsPerPage}
        setPage={setPage}
        itemsPerPage={itemsPerPage}
      />
      <Pagination
        className="pagination justify-content-end col-sm-6 col-md-6 col-lg-6"
        listClassName="justify-content-end mb-0"
      >
        {page !== 1 ? (
          <PaginationItem>
            <PaginationLink
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                setPage((prevPage: number) => prevPage - 1);
              }}
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
        ) : null}
        <PaginationRange paginationRange={paginationRange} setPage={setPage} page={page} />
        {page !== totalPageCount && totalPageCount !== 0 ? (
          <PaginationItem>
            <PaginationLink
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                setPage((prevPage: number) => prevPage + 1);
              }}
            >
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
          </PaginationItem>
        ) : null}
      </Pagination>
    </CardFooter>
  );
};

export default PaginationComponent;
