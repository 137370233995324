import React from "react";
import { Tooltip, Collapse, Card, CardBody } from "reactstrap";
// import { IS_EXPANDABLE_ROWS } from '../../utils/constants';
import { IPackage } from "../../utils/interfaces";
// import PackageDetailsTable from '../PackageDetailsTable/PackageDetailsTable';
import RenderFieldValue from "./renderFieldValue";

const TableBody = ({
  orders,
  tableConfig,
}: // currentTab,
// setIsDetailsOpened,
// handleToggleTooltip,
// isTooltipOpen,
// setCurrentTab,
// setFilter,
// setFilterState,
// isPopoverOpen,
// setIsPopoverOpen,
// isDetailsOpened,
any) => {
  return (
    <tbody>
      {orders &&
        orders.length &&
        orders.map((packageItem: IPackage, id: number) => (
          <React.Fragment key={packageItem.invoiceId}>
            <tr
            // onClick={() => {
            //   // if (IS_EXPANDABLE_ROWS.indexOf(currentTab) === -1) return;

            //   if (isDetailsOpened.includes(packageItem.invoiceId)) {
            //     setIsDetailsOpened((state: any) => {
            //       return state.filter((el: any) => el !== packageItem.invoiceId);
            //     });
            //   } else {
            //     setIsDetailsOpened((state: any) => {
            //       return [...state, packageItem.invoiceId];
            //     });
            //   }
            // }}
            >
              {Object.entries(tableConfig as keyof typeof tableConfig).map(
                ([configKey, config], id) => {
                  return (
                    <td
                      key={id}
                      className={
                        config.type === "string" &&
                        packageItem[configKey as keyof typeof packageItem] &&
                        // @ts-ignore
                        packageItem[configKey as keyof typeof packageItem].length > 70
                          ? "truncate-text pl-2 pr-2 pt-1 pb-1"
                          : "pl-2 pr-2 pt-1 pb-1"
                      }
                      id={`tooltip${packageItem.invoiceId}${id}`}
                    >
                      {/* {config.type === "string" &&
                    packageItem[configKey as keyof typeof packageItem] &&
                    // @ts-ignore
                    packageItem[configKey as keyof typeof packageItem].length > 70 ? (
                      <Tooltip
                        delay={0}
                        target={`tooltip${packageItem.invoiceId}${id}`}
                        style={{ width: "900px" }}
                        toggle={() => handleToggleTooltip(`tooltip${packageItem.invoiceId}${id}`)}
                        isOpen={isTooltipOpen === `tooltip${packageItem.invoiceId}${id}`}
                        placement="right"
                        className="tooltip"
                      >
                        {packageItem[configKey as keyof typeof packageItem]}
                      </Tooltip>
                    ) : null} */}
                      <RenderFieldValue
                        configKey={configKey}
                        packageItem={packageItem}
                        config={config}
                        // setCurrentTab={setCurrentTab}
                        // setFilter={setFilter}
                        // setFilterState={setFilterState}
                        // isPopoverOpen={isPopoverOpen}
                        // setIsPopoverOpen={setIsPopoverOpen}
                      />
                    </td>
                  );
                },
              )}
            </tr>
            {/* <tr>
            <td colSpan={17} className="p-0">
              <Collapse isOpen={isDetailsOpened.includes(packageItem.invoiceId)}>
                <Card className="m-0">
                  <CardBody>
                    <PackageDetailsTable
                      invoiceIds={isDetailsOpened}
                      isDetailsOpened={isDetailsOpened.includes(packageItem.invoiceId)}
                      openedInvoiceId={packageItem.invoiceId}
                    />
                  </CardBody>
                </Card>
              </Collapse>
            </td>
          </tr> */}
          </React.Fragment>
        ))}
    </tbody>
  );
};

export default TableBody;
