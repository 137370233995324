import { useApolloClient } from "@apollo/client";
import { Formik } from "formik";
import { gapi } from "gapi-script";
import React from "react";
import GoogleLogin from "react-google-login";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { errorMsg } from "../../cache/vars";
import { useRoleContext } from "../../Navigator";
import { GOOGLE_SIGN_UP, USER_SIGN_UP } from "../../queries/auth";
import "./formStyles.scss";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  password: Yup.string().min(8).max(32).required("Required"),
  confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

const SignUpPage: React.FC = () => {
  const { setRole } = useRoleContext();

  const history = useHistory();
  const client = useApolloClient();

  React.useEffect(() => {
    const initClient = () => {
      gapi.auth2.init({
        clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  const onSuccess = async (res: any) => {
    const signUpResponse = await client.query({
      query: GOOGLE_SIGN_UP,
      variables: { token: res.tokenId },
      fetchPolicy: "no-cache",
    });

    const jwt = document.cookie.match(/jwt=[^\s]+/);
    const jwtValue = jwt && jwt[0];

    if (signUpResponse.data?.googleSignup.user.role === "RECIPIENT" && jwtValue) {
      setRole(signUpResponse.data?.googleSignup.user.role);

      if (signUpResponse.data?.googleSignup.user.role === "RECIPIENT") {
        history.push("/dashboard");
      }
    }
  };

  const onFailure = (err: any) => {
    console.error("failed:", err);
    errorMsg("Something went wrong");
  };

  return (
    <Container className="mt-8 pb-5">
      <Row className="justify-content-center">
        <Col lg="5" md="7">
          <Card className="bg-secondary border-0 mb-0">
            <CardBody className="px-lg-5 py-lg-5">
              <Formik
                initialValues={{ email: "", phoneNumber: "", password: "", confirmPassword: "" }}
                onSubmit={async (userInfo) => {
                  const { confirmPassword, ...rest } = userInfo;

                  const signUpResponse = await client.query({
                    query: USER_SIGN_UP,
                    variables: { registerInput: rest },
                    fetchPolicy: "no-cache",
                  });

                  const jwt = document.cookie.match(/jwt=[^\s]+/);
                  const jwtValue = jwt && jwt[0];

                  if (
                    (signUpResponse.data?.userRegister.user.role === "RECIPIENT" ||
                      signUpResponse.data?.userRegister.user.role === "BROKER") &&
                    jwtValue
                  ) {
                    setRole(signUpResponse.data?.userRegister.user.role);

                    if (
                      signUpResponse.data?.userRegister.user.role === "RECIPIENT" &&
                      !signUpResponse.data?.userRegister.user.isEmailConfirmed
                    ) {
                      history.push("/email-not-confirmed");
                    }

                    if (
                      signUpResponse.data?.userRegister.user.role === "RECIPIENT" &&
                      signUpResponse.data?.userRegister.user.isEmailConfirmed
                    ) {
                      history.push("/dashboard");
                    }

                    if (signUpResponse.data?.userRegister.user.role === "BROKER") {
                      history.push("/broker-dashboard");
                    }
                  }
                }}
                validationSchema={VALIDATION_SCHEMA}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <Form role="form" onSubmit={handleSubmit}>
                      <FormGroup>
                        <Input
                          type="text"
                          name="email"
                          placeholder="Email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          invalid={!!errors.email && !!touched.email}
                        />
                        {errors.email && touched.email ? (
                          <FormFeedback>Wrong email format</FormFeedback>
                        ) : (
                          <FormText>Enter email</FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          type="text"
                          value={values.phoneNumber}
                          name="phoneNumber"
                          placeholder="Phone Number"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          invalid={!!errors.phoneNumber && touched.phoneNumber}
                        />
                        {errors.phoneNumber && touched.phoneNumber ? (
                          <FormFeedback>
                            Phone number should contain only numbers and have at least 6 symbols
                          </FormFeedback>
                        ) : (
                          <FormText>Enter phone number</FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          value={values.password}
                          name="password"
                          placeholder="Password"
                          type="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          invalid={!!errors.password && touched.password}
                        />
                        {errors.password && touched.password ? (
                          <FormFeedback>
                            Password should contain min 8 and max 32 symbols
                          </FormFeedback>
                        ) : (
                          <FormText>Enter password</FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          value={values.confirmPassword}
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          type="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          invalid={!!errors.confirmPassword && touched.confirmPassword}
                        />
                        {errors.confirmPassword && touched.confirmPassword ? (
                          <FormFeedback>
                            Password and confirm password should be the same
                          </FormFeedback>
                        ) : (
                          <FormText>Confirm password</FormText>
                        )}
                      </FormGroup>
                      <div className="text-center d-flex flex-column">
                        <Button
                          className="my-4"
                          color="info"
                          type="submit"
                          disabled={!dirty && isSubmitting}
                        >
                          Sign Up
                        </Button>
                        <GoogleLogin
                          clientId={clientId}
                          buttonText="Sign Up with Google"
                          onSuccess={onSuccess}
                          onFailure={onFailure}
                          cookiePolicy={"single_host_origin"}
                        />
                        <button
                          style={{
                            border: "none",
                            background: "none",
                            color: "#5e72e4",
                            fontSize: "80%",
                            marginTop: "24px",
                            marginBottom: "0px",
                          }}
                          onClick={handleReset}
                        >
                          Reset form
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6" className="text-left">
              <Link to="/login" className="text-primary">
                <small>Back</small>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUpPage;
