import { CART_FILTERS, CARTS_BY_FILTERS } from "../../utils/constants";

import "./cardFilter.scss";

const PackageInfoCartFilters = ({
  activeCardsFilter,
  setActiveCardsFilter,
  availableFilters,
  missingInfoCards,
  isShowAll = true,
  isNeedsAttention = true,
}: any) => {
  return (
    <div className="card-filters-container">
      {Object.entries(CART_FILTERS).map(([key, value], id) => {
        if (CART_FILTERS.needsAttention === value && !missingInfoCards?.length) {
          return null;
        }
        if (value === CART_FILTERS.showAll && !isShowAll) {
          return null;
        }
        if (value === CART_FILTERS.needsAttention && !isNeedsAttention) {
          return null;
        }
        if (
          (CARTS_BY_FILTERS[key as keyof typeof CARTS_BY_FILTERS] &&
          CARTS_BY_FILTERS[key as keyof typeof CARTS_BY_FILTERS].every((el: any) => {
            return !availableFilters.includes(el);
          })) || key === 'vegasLimoTransfer'
        ) {
          return null;
        }
        
        return (
          <div key={id}>
            <button
              className={`filter-btn 
              ${
                CARTS_BY_FILTERS[key as keyof typeof CARTS_BY_FILTERS]?.some((el) =>
                  missingInfoCards.includes(el),
                ) || CART_FILTERS.needsAttention === value
                  ? "missing-info-sign"
                  : ""
              }
            ${
              value === activeCardsFilter || (!activeCardsFilter && value === "Show All")
                ? "active-filter"
                : ""
            }`}
              onClick={(e) => {
                e.preventDefault();

                if (key === CART_FILTERS.showAll) {
                  setActiveCardsFilter("");
                } else {
                  if (value === activeCardsFilter) {
                    setActiveCardsFilter("");
                  } else {
                    setActiveCardsFilter(value);
                  }
                }
              }}
              key={id}
            >
              {value}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default PackageInfoCartFilters;
