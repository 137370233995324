import calendarIcon from '../../../assets/img/icons/cards/calendar-icon.svg';

import './cardGuaranteed.scss';

interface CardGuaranteedProps {
   icon?: string;
   text: string;
   subtext?: string;
}

const CardGuaranteed = ({ icon = calendarIcon, text, subtext = 'Details will be provided as the event approaches' }: CardGuaranteedProps ) => {
    return (
    <div className="reservation-guaranteed-container">
        <div className="reservation-guaranteed-unpublished">
            <div className="reservation-guaranteed-unpublished-content">
            <img
                loading="lazy"
                src={icon}
                className="reservation-guaranteed-unpublished-icon"
                alt="calendar-icon"
            />
            <div className="reservation-guaranteed-unpublished-text">
                {text}
                <br />
                <br />
                {subtext}
            </div>
            </div>
        </div>
    </div>
  );
};

export default CardGuaranteed;
