const DateTabs = ({ showOrders, setShowOrders }: any) => {
  return (
    <div className="d-flex w-100 mt-4">
      <button
        className={`tab-left ${showOrders === "upcoming" ? "is-active-tabs-btn-left" : null}`}
        onClick={(e) => {
          e.preventDefault();
          setShowOrders("upcoming");
        }}
      >
        Upcoming
      </button>
      <button
        className={`tab-right ${showOrders === "archive" ? "is-active-tabs-btn-right" : null}`}
        onClick={(e) => {
          e.preventDefault();
          setShowOrders("archive");
        }}
      >
        Archive
      </button>
    </div>
  );
};

export default DateTabs;
