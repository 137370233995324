import arrowRightIcon from '../../../assets/img/icons/cards/arrow-right-icon.svg';

import './cardAction.scss';

interface CardActionProps {
    icon?: string;
    text?: string;
    handleClick?: () => void;
}

const CardAction = ({ icon, text, handleClick = () => null }: CardActionProps ) => {
    return (
    <div className="action-details-section" onClick={handleClick}>
        <div className="action-details-content">
            <img loading="lazy" src={icon} alt="help-icon" className="action-details-icon" />
            <span className="action-details-text">{text}</span>
        </div>
        <img loading="lazy" src={arrowRightIcon} alt="arrow-right-icon" className="action-details-arrow-icon" />
    </div>
  );
};

export default CardAction;
