import { useLazyQuery } from "@apollo/client";
import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { REQUEST_CONFIRM_EMAIL_LINK } from "../../queries/auth";
import { getUserEmail } from "../../utils/services";
import { successMsg } from "../../cache/vars";

const NotConfirmed: React.FC = ({}: any) => {
  const [requestLink, { data, loading, error }] = useLazyQuery(REQUEST_CONFIRM_EMAIL_LINK);

  return (
    <Container
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      fluid
    >
      <Card className="bg-secondary border-0 mb-0">
        <CardBody className="px-lg-5 py-lg-5">
          <Col>
            <Row>
              <h1>Verify Your Email</h1>
            </Row>
            <Row className="mb-3">
              <span>Check your email and click the link to activate your account.</span>
            </Row>
            <Row className="mb-3">
              <span>Click the link below in case you didn't receive verification email.</span>
            </Row>
            <Row>
              <button
                className="btn btn-primary"
                onClick={() => {
                  requestLink({
                    variables: {
                      email: getUserEmail(),
                    },
                  });

                  if (data?.requestConfirmEmailLink === "ok") {
                    successMsg("Link sent. Please, check your email.");
                  }
                }}
              >
                Resend verification email
              </button>
            </Row>
          </Col>
        </CardBody>
      </Card>
    </Container>
  );
};

export default NotConfirmed;
