import React from "react";
import "./pendingLabel.scss";

const PendingLabel: React.FC = () => {
  return (
    <div className="frame d-flex align-items-center">
      <span className="text-uppercase">pending</span>
    </div>
  );
};

export default PendingLabel;
