import moment from "moment";

export const CardSubtitleGoogleLink = ({ addressName, link, time }: any) => {
    let formattedTime = '';
    if (time && moment(time).isValid()) {
        const timeString = moment(time).format('hh:mm');
        const period = moment(time).format('A');
        formattedTime = `${timeString}\u00A0${period}`;
    }

    if (addressName) {
        return (
            <>
                <a href={link} target="_blank" rel="noreferrer">
                    {addressName}
                </a> {formattedTime && `- ${formattedTime}`}
            </>
        );
    }

    return formattedTime ? <>{formattedTime}</> : null;
};
