import { useState } from 'react';
import './cardDeclineBtn.scss';

interface CardDeclineBtnProps {
   visible?: boolean;
   handleClick: () => void;
}

const CardDeclineBtn = ({ visible = false, handleClick }: CardDeclineBtnProps ) => {
    const [isVisible, setIsVisible] = useState(visible);
    
    return (
        <>
            {isVisible && 
                <button
                    type="button"
                    onClick={async (e) => {
                        e.preventDefault();

                        if (handleClick) {
                            handleClick();
                            setIsVisible(false);
                        }
                    }}
                    className="card-decline-button"
                >
                    Decline Service
                </button>
            }
        </>
    );
};

export default CardDeclineBtn;
