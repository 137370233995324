import CryptoJS from "crypto-js/aes";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import { successMsg } from "../../cache/vars";
import MissingInfo from "../../components/labels/MissingInfo";
import PendingLabel from "../../components/labels/Pending";
import ProvideMissingInfo from "../../components/labels/ProvideMissingInfo";
import { CRYPTO_JS_SECRET } from "../../utils/constants";

function PackageCard({ showOrders, clientPackages }: any) {
  const history = useHistory();

  const openPackageInfoPage = (encryptedInvoiceId: string) => {
    const encryptedInvoiceIdWithReplacedSlashes = encryptedInvoiceId.replace(
      /\//g,
      "HjsOwpeSBqdLafaFsjhHfd73sb7kdheYTsd61",
    );

    history.push(`/package-info/${encryptedInvoiceIdWithReplacedSlashes}`);
  };

  return (
    <Col className="mt-4" style={{ overflow: "auto", height: "90vh", padding: "0" }}>
      {clientPackages && clientPackages.length ? (
        clientPackages.map((el: any, id: number) => {
          return (
            <Card
              key={id}
              style={{
                border: "1px solid #c2c2c2",
                borderRadius: "14px",
              }}
            >
              <CardHeader style={{ paddingLeft: "2.5rem", borderRadius: "14px" }}>
                <Row>
                  <Col sm="12" className="text-left">
                    <span>
                      {el.startDate} -&nbsp;
                      {el.endDate}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm="12" className="text-left">
                    <h2>{el.eventName}</h2>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" className="text-left">
                    <span>
                      {el.venueCity}, {el.venue} ({el.venueAddress})
                    </span>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="ml-3 pt-2">
                {el.isTicketsSent ? (
                  <Row className="mt-2">
                    <Col sm="12" className="text-left d-flex align-items-center">
                      <h2>Expected delivery by: {el.shipBy}</h2>
                    </Col>
                  </Row>
                ) : null}
                {el.isCardHasMissingInfo && showOrders !== "archive" ? (
                  <Row className="mb-4 mt-2">
                    <Col sm="12" className="text-left d-flex align-items-center">
                      <ProvideMissingInfo />
                    </Col>
                  </Row>
                ) : null}
                <Row className="mt-2">
                  <Col sm="6" className="text-left">
                    <i className="fa fa-thin fa-star" />
                    &nbsp;&nbsp;
                    <span className="text-primary text-capitalize">
                      {el?.section?.toLowerCase()} {el?.row?.toLowerCase()}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm="6" className="text-left d-flex align-items-center">
                    <i className="fa fa-thin fa-list" />
                    &nbsp;&nbsp;
                    <span className="text-primary">Itinerary</span>
                    &nbsp;&nbsp;
                    {el.isCardHasPendingInfo && showOrders !== "archive" ? <PendingLabel /> : null}
                    &nbsp;&nbsp;
                    {el.isCardHasMissingInfo && showOrders !== "archive" ? (
                      <MissingInfo isShowNote={false} isShowExclamationIcon={true} />
                    ) : null}
                  </Col>
                </Row>
                {el.trackingNumber.toUpperCase() !== "OVERRIDE" ? (
                  <Row className="mt-2">
                    <Col sm="6" className="text-left d-flex align-items-center">
                      <i className="fa fa-thin fa-box" />
                      &nbsp;&nbsp;
                      <span>
                        Tracking Number:&nbsp;
                        {el.trackingNumber ? el.trackingNumber : null}
                      </span>
                      &nbsp;&nbsp;
                      {el.trackingNumber ? (
                        <button
                          style={{
                            border: "none",
                            background: "none",
                            color: "#525f7f",
                            padding: "0",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(el.trackingNumber);
                            successMsg("Copied to clickboard");
                          }}
                        >
                          <i className="fa fa-copy"></i>
                        </button>
                      ) : (
                        <PendingLabel />
                      )}
                    </Col>
                  </Row>
                ) : null}
              </CardBody>
              <CardFooter
                style={{
                  borderRadius: "14px",
                }}
              >
                <Row className="ml-1 d-flex align-items-baseline">
                  {showOrders === "upcoming" ? (
                    <Col sm="6" className="text-left">
                      <span className="h4">Starts {el.startsIn}</span>
                    </Col>
                  ) : null}
                  <Col sm={showOrders === "upcoming" ? "6" : "12"} className="text-right">
                    <button
                      className="h4"
                      style={{
                        border: "none",
                        background: "none",
                        color: "#337ab7",
                        padding: "0",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        const encryptedInvoiceId = CryptoJS.encrypt(
                          `${el.invoiceId}`,
                          CRYPTO_JS_SECRET,
                        ).toString();

                        openPackageInfoPage(encryptedInvoiceId);
                      }}
                    >
                      Show More
                    </button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          );
        })
      ) : (
        <h1>Items Not Found</h1>
      )}
    </Col>
  );
}

export default PackageCard;
