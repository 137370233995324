import { useState } from "react";
import { Row, Col } from "reactstrap";
import {
  CARD_TYPES_TICKETS,
  CARD_FORM_SCHEMA,
  DEFAULT_CARD_CONFIG,
  FULLY_EDITABLE_CARDS,
} from "../../utils/constants";
import Cart from "./Card";
import CardDetails from "../StagedCard/CardDetails/CardDetails";
import CardGoogleMap from "../StagedCard/CardGoogleMap/CardGoogleMap";
import CardGuaranteed from "../StagedCard/CardGuaranteed/CardGuaranteed";
import { TicketActions } from "../Modal/TicketActions";
import { TicketsPreviewModal } from "../Modal/TicketsPreviewModal";
import CardAction from "../StagedCard/CardAction/CardAction";
import GetHelpModal from "../Modal/GetHelpModal";
import { truncateString } from "../../utils/services";

import copyIcon from "../../assets/img/icons/cards/copy-icon.svg";
import mapIcon from "../../assets/img/icons/cards/map-icon.svg";
import rocketIcon from "../../assets/img/icons/cards/rocket-icon.svg";
import ticketConfigIcon from "../../assets/img/icons/cards/ticket-config-icon.svg";
import ticketIcon from "../../assets/img/icons/common/ticket-icon.svg";
import flagIcon from "../../assets/img/icons/cards/flag-icon.svg";
import carIcon from "../../assets/img/icons/cards/car-icon.svg";
import preShowIcon from "../../assets/img/icons/cards/pre-show-icon.svg"; 
import partyIcon from "../../assets/img/icons/cards/party-icon.svg";
import helpIcon from '../../assets/img/icons/cards/help-icon.svg';

import "./ticketGroupCardNew.scss";
import "./transferGroupCardNew.scss";

const RenderCardBody = ({
  children,
  cart,
  parsedCardData,
  airportTransferType,
  setAirportTransferType,
  vegasLimoTransferType,
  setVegasLimoTransferType,
  isCartInfoOpenedId,
  handleOpenDetails,
  isInvoiceArchived,
  handleOpenLearnMore,
  isLearnMoreOpenedId,
  isPublished,
  cardGroup,
  cardsWithTickets,
  ticketsForCard,
  packageDetailsId,
  isServiceDeclined,
  serviceDeclined,
}: any) => {
  const [file, setFile] = useState<string | undefined>("");
  const [fileType, setFileType] = useState<string>("pdf");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string | undefined>("");

  const linkMdParser = (value: string) => {
    const regex = /^(.*?)\s*\(?\s*(https?:\/\/[^\s)]+)\s*\)?$/;
   
    const matches = value.match(regex);

    if (matches) {
      const label = matches[1].trim() || matches[2];
      const url = matches[2];
      return {
        label: label || value,
        url: url || value
      };
    }

    return {
      label: value,
      url: value
    };
  };
  
  const cartJsonData = JSON.parse(cart.cartDetails.cartJsonData);
  
  const cardTicket = cardsWithTickets?.find((card: any) => card.packageDetailsId === packageDetailsId);
  
  if ((cart.cartDetails?.isServiceDeclined === 'DECLINED') && !isPublished) {
    return (
    <>
      <GetHelpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tag={cardGroup.replaceAll(' ', '')} />
      
      <CardGuaranteed 
        text={isServiceDeclined && serviceDeclined !== 'UNDECLINED' ? "You’ve declined this service." : ""} 
        subtext={isServiceDeclined && serviceDeclined !== 'UNDECLINED' ? "If you need further assistance please use the Get Help button." : ''}
      />

      <div className="divider"></div>
      
      <CardAction icon={helpIcon} text="Get help" 
        handleClick={() => {
          setIsModalOpen(true)
        }}
      />
    </>)
  }

  return (
    <>
      {CARD_TYPES_TICKETS.includes(cart.group) ? (
        Object.entries(CARD_FORM_SCHEMA).map(([key, value]: any, id) => {
          if (cart.group === key) {
            if (key === "Airport Transfer") {
              const cartSchemaConfig = parsedCardData?.transferId
                ? value[`transfer${parsedCardData?.transferId}` as keyof typeof value]
                : value[airportTransferType as keyof typeof value];
              return (
                <Cart
                  cartSchemaConfig={cartSchemaConfig}
                  cartData={cart}
                  airportTransferType={airportTransferType}
                  setAirportTransferType={setAirportTransferType}
                  dataKey={key}
                  isCartInfoOpenedId={isCartInfoOpenedId}
                  handleOpenDetails={handleOpenDetails}
                  parsedCardDetails={parsedCardData?.[airportTransferType as keyof typeof value]}
                  isInvoiceArchived={isInvoiceArchived}
                  cartInfoId={id}
                  key={`cart-${id}-${key?.replaceAll(' ', '-')?.toLowerCase()}`}
                  cardsWithTickets={cardsWithTickets}
                  ticketsForCard={ticketsForCard}
                  packageDetailsId={packageDetailsId}
                />
              );
            }

            if (key === "Vegas Limo Transfer") {
              const cartSchemaConfig = parsedCardData?.transferId
                ? value[`transfer${parsedCardData?.transferId}` as keyof typeof value]
                : value[vegasLimoTransferType as keyof typeof value];
              return (
                <Cart
                  cartSchemaConfig={cartSchemaConfig}
                  cartData={cart}
                  vegasLimoTransferType={vegasLimoTransferType}
                  setVegasLimoTransferType={setVegasLimoTransferType}
                  dataKey={key}
                  isCartInfoOpenedId={isCartInfoOpenedId}
                  handleOpenDetails={handleOpenDetails}
                  parsedCardDetails={parsedCardData?.[vegasLimoTransferType as keyof typeof value]}
                  isInvoiceArchived={isInvoiceArchived}
                  cartInfoId={id}
                  key={`${key?.replaceAll(' ', '-')?.toLowerCase()}-${id}`}
                  cardsWithTickets={cardsWithTickets}
                  ticketsForCard={ticketsForCard}
                  packageDetailsId={packageDetailsId}
                />
              );
            }
            
            if (key === "Ticket") {
              const { venue, venueAddress, venueСity } = cartJsonData || {};
              if (isPublished) {
                return (
                  <div key={`${key?.replaceAll(' ', '-')?.toLowerCase()}-${id}`}>
                    <CardDetails
                      cardIcon={rocketIcon}
                      cardTitle="Event"
                      cardSubtitle={cartJsonData?.performerName}
                      isToggleExpanded={false}
                    ></CardDetails>
                  
                    <div className="divider" />
                    <CardDetails
                      cardIcon={mapIcon}
                      cardTitle="Venue"
                      cardSubtitleGoogleLink={{
                        name: cartJsonData?.venue === cartJsonData?.venueAddress ? truncateString(cartJsonData?.venue, 30) : cartJsonData?.venue,
                        address: !cartJsonData?.venue ? truncateString(cartJsonData?.venueAddress, 30) : cartJsonData?.venueAddress,
                        link: `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                          [venue, venueAddress, venueСity].filter(Boolean).join(' '))}`,
                        time: cartJsonData?.date || ''
                      }}
                    >
                      <>
                        {/* <div className="transfer-card-content">
                          <div className="card-content-column">
                            <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Phone</p>
                          </div>
                          <div className="card-content-column">
                            <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                              <a href="tel:+">{cartJsonData?.venuePhoneNumber}</a>
                            </p>
                          </div>
                        </div> */}
                        <div className="transfer-card-content">
                          <div className="card-content-column">
                            <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>City</p>
                          </div>
                          <div className="card-content-column">
                            <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.venueCity}</p>
                          </div>
                        </div>
                        
                        {/* <div className="transfer-card-content">
                          <div className="card-content-column">
                            <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Website</p>
                          </div>
                          <div className="card-content-column">
                            <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: 'nowrap' }}>
                              <a href={cartJsonData?.website} rel="noreferrer" target="_blank">{cartJsonData?.website}</a>
                            </p>
                          </div>
                        </div> */}

                      </>
                      <CardGoogleMap address={cartJsonData?.placeValue ? cartJsonData?.venueAddress : cartJsonData?.venue} className="pl-4" />
                    </CardDetails>
                  
                    <div className="divider" />
                    
                    <CardDetails
                      cardIcon={ticketConfigIcon}
                      cardTitle="Tickets"
                      cardSubtitle={cartJsonData?.ticketsSentDate && cartJsonData?.ticketsSentDate !== "-" ? `Your tickets were sent on ${cartJsonData?.ticketsSentDate}` : 'Your tickets are confirmed. Details on how to access your tickets will be available soon.'}
                      isToggleExpanded={false}
                    ></CardDetails>
                    
                    {cardTicket &&
                      <>
                        <div className="divider" />
                        <CardDetails
                          cardIcon={ticketIcon}
                          cardTitle="Access Tickets now"
                          children={
                            <div className="mt-4">
                              {ticketsForCard && ticketsForCard.length ? (
                                ticketsForCard.map((ticket: any, index: number) => (
                                  <TicketActions
                                    key={`ticket-action-${index}`}
                                    ticket={ticket}
                                    setFile={setFile}
                                    setIsModalOpened={setIsModalOpened}
                                    setSelectedFile={setSelectedFile}
                                  />
                                ))
                              ) : (
                                <p>No tickets available</p>
                              )}
                            </div>
                          }
                        />
                        <TicketsPreviewModal
                          isModalOpened={isModalOpened}
                          setIsModalOpened={setIsModalOpened}
                          selectedFile={selectedFile}
                          fileType={fileType}
                        />
                      </>
                    }
                  
                    <div className="divider" />
                    
                    {cartJsonData?.notes &&
                      <>
                        <CardDetails
                          cardIcon={flagIcon}
                          cardTitle="Additional Notes"
                          // cardDescription={cartJsonData.notes}
                          cardDescription={
                            <span
                              style={{ whiteSpace: 'pre-line' }}
                              dangerouslySetInnerHTML={{
                                __html: cartJsonData.notes,
                              }}>
                            </span>
                          }
                        />
                        <div className="divider" />
                      </>
                    }
                       
                    <CardAction icon={helpIcon} text="Get help" 
                      handleClick={() => {
                        setIsModalOpen(true)
                      }}
                    />
                    
                    <GetHelpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tag={cardGroup.replaceAll(' ', '')} />
                  </div>
                )
              } else {
                return (
                  <div key={`${id}-${key.replaceAll(' ', '-').toLowerCase()}`}>
                    {cartJsonData?.performerName &&
                      <>
                        <CardDetails
                        isToggleExpanded={false}
                        cardIcon={rocketIcon}
                        cardTitle="Event"
                        cardSubtitle={cartJsonData?.performerName}
                        ></CardDetails>

                        <div className="divider"></div>
                      </>
                    }

                    <CardDetails
                      isToggleExpanded={false}
                      cardIcon={rocketIcon}
                      cardTitle="Event Details"
                      cardSubtitle={cartJsonData?.performerName}
                    >
                      <>
                        <CardGuaranteed
                          text={`Your package includes ${cart?.description.toLowerCase()}.`}
                          subtext="Details on how to access your tickets will be available as the event approaches."
                        />
                      </>
                    </CardDetails>
                    
                    
                    
                    {/* <CardAction icon={jumpIcon} text="Jump to departure details" handleClick={() => alert('Jump to departure details')} /> */}
                    
                    {/* <div className="divider"></div>  */}

                    {!isPublished && 
                      children && <>{children}</>
                    }
                  </div>
                )
              }
            }
            
            if (key === "Transfer") {
              if (isPublished) {
                return (
                  <div key={`${key?.replaceAll(' ', '-')?.toLowerCase()}-${id}`}>
                    <CardDetails
                      cardIcon={carIcon}
                      cardTitle="Vendor Name"
                      cardSubtitle={cartJsonData?.vendorName}
                    >
                      <div className="transfer-card-content">
                        <div className="card-content-column">
                          {cartJsonData?.vendorPhone && <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Vendor phone</p>}
                         {cartJsonData?.confirmationNumber && <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Confirmation number</p>}
                        </div>
                        <div className="card-content-column">
                          {cartJsonData?.vendorPhone && 
                            <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                              <a href="tel:+">{cartJsonData?.vendorPhone}</a>
                            </p>
                          }
                          {cartJsonData?.confirmationNumber &&
                            <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.confirmationNumber}</p>
                          }
                        </div>
                      </div>
                    </CardDetails>
                    
                    <div className="divider" />
                    
                    <CardDetails
                      cardIcon={mapIcon}
                      cardTitle="Pick Up At"
                      cardSubtitleGoogleLink={{
                        name: cartJsonData?.pickUpName === cartJsonData?.pickUpPlaceName ? truncateString(cartJsonData?.pickUpName, 30) : cartJsonData?.pickUpName,
                        address: !cartJsonData?.pickUpName ? truncateString(cartJsonData?.pickUpPlaceName, 30) : cartJsonData?.pickUpPlaceName,
                        link: cartJsonData?.pickUpPlaceLink, 
                        time: cartJsonData?.date || ''
                      }}
                    >
                      <CardGoogleMap address={cartJsonData?.pickUpPlaceName || cartJsonData?.pickUpAddress} className="pl-4" />
                    </CardDetails>
                  
                    <div className="divider" />
                    <CardDetails
                      cardIcon={preShowIcon}
                      cardTitle="Pre Show Hospitality At"
                      cardSubtitleGoogleLink={{
                        name: cartJsonData?.restaurantName === cartJsonData?.restaurantPlaceName ? truncateString(cartJsonData?.restaurantName, 30) : cartJsonData?.restaurantName,
                        address: !cartJsonData?.restauranteName ? truncateString(cartJsonData?.restaurantPlaceName, 30) : cartJsonData?.restaurantPlaceName,
                        link: cartJsonData?.restaurantPlaceLink, 
                        time: cartJsonData?.preShowHospitality || ''
                      }}
                    >
                      <CardGoogleMap address={cartJsonData?.restaurantPlaceName || cartJsonData?.restaurantName} className="pl-4" />
                    </CardDetails>
                    
                    <div className="divider" />
                    
                    <CardDetails
                      cardIcon={partyIcon}
                      cardTitle="Event At"
                      cardSubtitleGoogleLink={{
                        name: cartJsonData?.venue === cartJsonData?.venueAddress ? truncateString(cartJsonData?.venue, 30) : cartJsonData?.venue,
                        address: !cartJsonData?.venue ? truncateString(cartJsonData?.venueAddress, 30) : cartJsonData?.venueAddress,
                        link: `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(cartJsonData?.venueAddress || '')}`, 
                        time: cartJsonData?.startTime || ''
                      }}
                    >
                      <CardGoogleMap address={cartJsonData?.venueAddress || cartJsonData?.venue} className="pl-4" />
                    </CardDetails>
                    
                    <div className="divider" />
                    
                    <CardDetails
                      cardIcon={mapIcon}
                      cardTitle="Drop Off At"
                      cardSubtitleGoogleLink={{
                        name: cartJsonData?.dropOffeName === cartJsonData?.dropOffPlaceName ? truncateString(cartJsonData?.dropOffeName, 30) : cartJsonData?.dropOffName,
                        address: !cartJsonData?.dropOffName ? truncateString(cartJsonData?.dropOffPlaceName, 30) : cartJsonData?.dropOffPlaceName,
                        link: cartJsonData?.dropOffPlaceLink, 
                        time: cartJsonData?.dropOff || ''
                      }}
                    >
                      <CardGoogleMap address={cartJsonData?.dropOffPlaceName || cartJsonData?.dropOffAddress} className="pl-4" />
                    </CardDetails>
                    
                    <div className="divider" />
                    
                    {cartJsonData?.notes &&
                      <>
                        <CardDetails
                          cardIcon={flagIcon}
                          cardTitle="Additional Notes"
                          cardDescription={cartJsonData.notes}
                        />
                        
                        <div className="divider" />
                      </>
                    }
                    
                    <CardAction icon={helpIcon} text="Get help" 
                      handleClick={() => {
                        setIsModalOpen(true)
                      }}
                    />
                    
                    <GetHelpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tag={cardGroup.replaceAll(' ', '')} />
                    
                    <TicketsPreviewModal
                      isModalOpened={isModalOpened}
                      setIsModalOpened={setIsModalOpened}
                      selectedFile={selectedFile}
                      fileType={fileType}
                    />
                  </div>
                )
              } else {
                return (
                  <div key={`${key?.replaceAll(' ', '-')?.toLowerCase()}-${id}`}>
                    <CardDetails
                      isToggleExpanded={false}
                      cardIcon={carIcon}
                      cardTitle="Transportation Details"
                    >
                      <>
                        <CardGuaranteed text="Details will be provided as the event approaches." subtext="" />
                      </>
                    </CardDetails>
                    
                    {/* <CardAction icon={jumpIcon} text="Jump to departure details" handleClick={() => alert('Jump to departure details')} /> */}
                    
                    {/* <div className="divider"></div>  */}

                    {!isPublished && 
                      children && <>{children}</>
                    }
                  </div>
                )
              }
            }

            if (CARD_TYPES_TICKETS.includes(cart?.group) && !cart?.cartDetails.isPublished) {
              return (
                <>
                  <Cart
                    cartSchemaConfig={DEFAULT_CARD_CONFIG}
                    parsedCardDetails={cart}
                    isInvoiceArchived={isInvoiceArchived}
                    cartInfoId={id}
                    key={`${key?.replaceAll(' ', '-')?.toLowerCase()}-${id}`}
                    cardsWithTickets={cardsWithTickets}
                    ticketsForCard={ticketsForCard}
                    packageDetailsId={packageDetailsId}
                  />
                  {children && <>{children}</>}
                </>
              );
            }

            return (
              <>
              <Cart
                cartSchemaConfig={value}
                cartData={cart}
                airportTransferType={airportTransferType}
                setAirportTransferType={setAirportTransferType}
                vegasLimoTransferType={vegasLimoTransferType}
                setVegasLimoTransferType={setVegasLimoTransferType}
                dataKey={key}
                isCartInfoOpenedId={isCartInfoOpenedId}
                handleOpenDetails={handleOpenDetails}
                parsedCardDetails={parsedCardData}
                cartInfoId={id}
                handleOpenLearnMore={handleOpenLearnMore}
                isLearnMoreOpenedId={isLearnMoreOpenedId}
                isInvoiceArchived={isInvoiceArchived}
                key={`${key?.replaceAll(' ', '-')?.toLowerCase()}-${id}`}
                cardsWithTickets={cardsWithTickets}
                ticketsForCard={ticketsForCard}
                packageDetailsId={packageDetailsId}
              /> 
              {children && <>{children}</>}
              </>
            );
          }
          return null;
        })
      ) : FULLY_EDITABLE_CARDS.includes(cart.group) && parsedCardData ? (
        <div>
          {cart?.cartDetails.isPublished ? (
            <div>
              {Array.isArray(parsedCardData) && cart.group !== "VIP Club Access"
                ? parsedCardData.map((field: any, id: number) => {
                    return Object.entries(field).map(([key, value]: any) => {
                      if (key.toLowerCase() === "link" && !value) return null;
                      
                      if (key.toLowerCase() === 'notes' && value) {
                        return (
                          <div key={`row-notes-${id}-${key?.replaceAll(' ', '-')?.toLowerCase()}`}>
                            <div className="divider" />

                            <CardDetails
                              cardIcon={flagIcon}
                              cardTitle="Additional Notes"
                              cardDescription={value}
                            />
                          </div>
                        );
                      }
                      
                      return (
                        <Row
                          style={{
                            // paddingLeft: "15px",
                            paddingRight: "15px",
                            marginTop: id === 0 ? "" : "6px",
                            marginBottom: "20px"
                          }}
                          key={`default-row-${id}-${key?.replaceAll(' ', '-')?.toLowerCase()}`}
                        >
                          <Col
                            xs="6"
                            className="d-flex"
                            // style={{ paddingLeft: id === 0 ? "" : "3.25rem" }}
                          >
                            {id === 0 ? (
                              <div>
                                <img src={copyIcon} alt="copy-icon" 
                                style={{
                                  width: "24px",
                                  textAlign: "center",
                                  marginRight: "0.5rem",
                                }}/>
                              </div>
                            ) : null}
                            <span
                              className="text-capitalize"
                              style={{
                                marginLeft: id === 0 ? "0px" : "36px",
                              }}
                            >
                              {key
                                .split(/(?=[A-Z]|[0-9])/)
                                .join(" ")
                                .toLowerCase()}
                            </span>
                          </Col>
                          <Col>
                            {key.toLowerCase() === 'link' && cart.group === 'Other'
                              ? <a href={linkMdParser(value).url} rel="noreferrer" target="_blank">{linkMdParser(value).label}</a>
                              : <span className="text-capitalize">{value ? value : "-"}</span>
                            }
                          </Col>
                        </Row>
                      );
                    });
                  })
                : 
                parsedCardData
                  .filter((el: any) => {
                    const key = Object.keys(el)[0];
                    if (key === "ticketsSent" || key === "ticketsSentDate") {
                      return false;
                    }
                    return true;
                  })
                  .map((field: any, id: number) => {
                    return Object.entries(field).map(([key, value]: any) => {
                      if (key.toLowerCase() === 'notes' && value) {
                        return null;
                      }
                      
                      return (
                        <Row
                          style={{
                            // paddingLeft: "15px",
                            paddingRight: "15px",
                            marginTop: id === 0 ? "" : "6px",
                            marginBottom: "20px"
                          }}
                          key={`row-${id}-${key?.replaceAll(' ', '-')?.toLowerCase()}`}
                        >
                          <Col
                            xs="6"
                            className="d-flex"
                            // style={{ paddingLeft: id === 0 ? "" : "3.25rem" }}
                          >
                            {id === 0 ? (
                              <div>
                                <img src={copyIcon} alt="copy-icon" 
                                  style={{
                                    width: "24px",
                                    textAlign: "center",
                                    marginRight: "0.5rem",
                                  }}
                                />
                              </div>
                            ) : null}
                            <span
                              className="text-capitalize"
                              style={{
                                marginLeft: id === 0 ? "0px" : "36px",
                              }}
                            >
                              {key
                                .split(/(?=[A-Z]|[0-9])/)
                                .join(" ")
                                .toLowerCase()}
                            </span>
                          </Col>
                          <Col>
                            {/* <img src={copyIcon} alt="copy-icon" 
                              style={{
                                width: "24px",
                                textAlign: "center",
                                marginRight: "0.5rem",
                              }}
                            /> */}
                            <span className="text-capitalize">{value ? value : "-"}</span>
                          </Col>
                        </Row>
                      );
                    } );
                  }
                )
              }

              {parsedCardData.length !== 0 ? <div className="divider"></div> : null}
                  
              <CardAction icon={helpIcon} text="Get help" 
                handleClick={() => {
                  setIsModalOpen(true)
                }}
              />
              
              <GetHelpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tag={cardGroup.replaceAll(' ', '')} />
            </div>
          ) : (
            <>
              <Cart
                cartSchemaConfig={DEFAULT_CARD_CONFIG}
                parsedCardDetails={cart}
                isInvoiceArchived={isInvoiceArchived}
                cardsWithTickets={cardsWithTickets}
                ticketsForCard={ticketsForCard}
                packageDetailsId={packageDetailsId}
              />
              {children && <>{children}</>}
            </>
          )}
        </div>
      ) : (
        <>
          <Cart 
            cartSchemaConfig={DEFAULT_CARD_CONFIG} 
            parsedCardDetails={cart} 
            cardsWithTickets={cardsWithTickets}
            ticketsForCard={ticketsForCard}
            packageDetailsId={packageDetailsId}
          />
          {!isPublished  && children && 
            <>{children}</>
          }
        </>
      )}
    </>
  );
};

export default RenderCardBody;
