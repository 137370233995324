import { PaginationItem, PaginationLink } from "reactstrap";
import { DOTS } from "../../utils/constants";

const PaginationRange = ({ paginationRange, setPage, page }: any) => {
  return (
    <>
      {paginationRange.map((pageNumber: "DOTS" | number, id: number) => {
        if (pageNumber === DOTS) {
          return (
            <li key={id} className="pagination-item dots">
              &#8230;
            </li>
          );
        }

        return (
          <PaginationItem
            key={id}
            className={page === pageNumber ? "active" : ""}
            onClick={(e) => {
              e.preventDefault();
              setPage(pageNumber);
            }}
          >
            <PaginationLink>{pageNumber}</PaginationLink>
          </PaginationItem>
        );
      })}
    </>
  );
};

export default PaginationRange;
