const FEEDBACK_MAIL_BODY = `%0D%0A
%0D%0A
%0D%0A ---------------
%0D%0A
%0D%0A PLEASE WRITE YOUR MESSAGE ABOVE THIS LINE AND LEAVE EVERYTHING BELOW
%0D%0A
%0D%0A Elevate VIP Feedback
`;

const FEEDBACK_MAIL_SUBJECT = "Elevate VIP Feedback";

const SUPPORT_MAIL = "support@elevatevip.zendesk.com";

const NotFound = () => {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1>Invoice cannot be found</h1>
      <span>
        If you have any questions please&nbsp;
        <a
          className=""
          href={`mailto:${SUPPORT_MAIL}?subject=${FEEDBACK_MAIL_SUBJECT}&body=${FEEDBACK_MAIL_BODY}`}
        >
          contact us
        </a>
        .
      </span>
    </div>
  );
};

export default NotFound;
