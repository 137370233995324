import "./missingInfo.scss";

const MissingInfo = ({
  isShowNote,
  isClickable,
  parsedCardData,
  setModalType,
  setPackageDetailsId,
  setIsModalOpened,
  setAirportTransferType,
  setVegasLimoTransferType,
  cart,
  isShowExclamationIcon,
}: {
  isShowNote: boolean;
  isClickable?: boolean;
  parsedCardData?: any;
  setModalType?: any;
  setPackageDetailsId?: any;
  setIsModalOpened?: any;
  setAirportTransferType?: any;
  setVegasLimoTransferType?: any;
  cart?: any;
  isShowExclamationIcon: boolean;
}) => {
  return isClickable ? (
    <button
      style={{
        border: "none",
        background: "none",
      }}
      className="d-flex align-items-center label-container"
      onClick={() => {
        if (parsedCardData.transferId) {
          if (parsedCardData.transferId === "1") {
            setModalType(cart.type);
            setPackageDetailsId(cart.packageDetailsId);
            setIsModalOpened(true);
            return;
          }
          setAirportTransferType(`transfer${parsedCardData.transferId}`);
          setVegasLimoTransferType(`transfer${parsedCardData.transferId}`);
        }
        setModalType(cart.type);
        setPackageDetailsId(cart.packageDetailsId);
        setIsModalOpened(true);
      }}
    >
      {isShowNote ? <div className="exclamation-circle"></div> : null}
      <div className="frame d-flex align-items-center">
        <span className="missing-info-label align-items-center">missing information</span>
      </div>
      {isShowNote ? (
        <span className="info-note">We need more details from you to coordinate this trip.</span>
      ) : null}
    </button>
  ) : (
    <div className="d-flex align-items-center label-container">
      {isShowExclamationIcon ? <div className="exclamation-circle"></div> : null}
      <div className="d-flex align-items-center">
        <span className="missing-info-label align-items-center text-capitalize">
          missing information
        </span>
      </div>
      {isShowNote ? (
        <span className="info-note">We need more details from you to coordinate this trip.</span>
      ) : null}
    </div>
  );
};

export default MissingInfo;
