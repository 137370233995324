import { useMutation } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import { CONFIRM_EMAIL } from "../../queries/auth";

const ConfirmEmailPage: React.FC = ({
  match: {
    params: { token },
  },
}: any) => {
  const [confirmEmail, { data, loading, error }] = useMutation(CONFIRM_EMAIL, {
    variables: {
      token,
    },
  });

  React.useEffect(() => {
    confirmEmail();
  }, []);

  return (
    <Container className="mt-8 pb-5">
      <Row className="justify-content-center">
        <Col lg="5" md="7">
          <Card className="bg-secondary border-0 mb-0">
            <CardBody className="px-lg-5 py-lg-5 text-center">
              {loading ? (
                <>
                  <h1>Email Confirmation...</h1>
                  <Spinner animation="border" variant="info" />
                </>
              ) : error ? (
                <h1>{error.message}</h1>
              ) : (
                <h1>Email Confirmed!</h1>
              )}
            </CardBody>
          </Card>
          {!loading ? (
            <Row className="mt-3">
              <Col xs="6" className="text-left">
                <Link to="/login" className="text-primary">
                  <small>Back To Login Page</small>
                </Link>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default ConfirmEmailPage;
