import moment from "moment";
import "./transferTypeButtons.scss";

const TransferTypeButtons = ({
  airportTransferType,
  setAirportTransferType,
  vegasLimoTransferType,
  setVegasLimoTransferType,
  transfer1Date,
  transfer2Date,
}: any) => {
  if (vegasLimoTransferType) {
    return (
      <div className="d-flex w-100">
        <button
          className={`tab-left ${
            vegasLimoTransferType === "transfer1"
              ? "is-active-tabs-btn-left"
              : null
          }`}
          onClick={(e) => {
            e.preventDefault();
            setVegasLimoTransferType("transfer1");
          }}
        >
          <i className="fa fa-lg fa-solid fa-car"></i>&nbsp;&nbsp; Transfer 1{" "}
          {transfer1Date ? (
            <> — {moment(transfer1Date).format("h:mm a")}</>
          ) : (
            ""
          )}
        </button>
        <button
          className={`tab-right ${
            vegasLimoTransferType === "transfer2"
              ? "is-active-tabs-btn-right"
              : null
          }`}
          onClick={(e) => {
            e.preventDefault();
            setVegasLimoTransferType("transfer2");
          }}
        >
          <i className="fa fa-lg fa-solid fa-car"></i>&nbsp;&nbsp; Transfer 2{" "}
          {transfer2Date ? (
            <> — {moment(transfer2Date).format("h:mm a")}</>
          ) : (
            ""
          )}
        </button>
      </div>
    );
  }
  else {
    return (
      <div className="d-flex w-100">
        <button
          className={`tab-left ${
            airportTransferType === "transfer1" ? "is-active-tabs-btn-left" : null
          }`}
          onClick={(e) => {
            e.preventDefault();
            setAirportTransferType("transfer1");
          }}
        >
          <i className="fa fa-lg fa-solid fa-plane"></i>&nbsp;&nbsp; Transfer 1{" "}
          {transfer1Date ? <> — {moment(transfer1Date).format("h:mm a")}</> : ""}
        </button>
        <button
          className={`tab-right ${
            airportTransferType === "transfer2" ? "is-active-tabs-btn-right" : null
          }`}
          onClick={(e) => {
            e.preventDefault();
            setAirportTransferType("transfer2");
          }}
        >
          <i className="fa fa-lg fa-solid fa-plane"></i>&nbsp;&nbsp; Transfer 2{" "}
          {transfer2Date ? <> — {moment(transfer2Date).format("h:mm a")}</> : ""}
        </button>
      </div>
    );
  }
};

export default TransferTypeButtons;
