import { useApolloClient } from "@apollo/client";
import jwtDecode from "jwt-decode";
import decodeToken from "jwt-decode";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import { GET_PROFILE } from "../../queries/auth";
import { GET_PACKAGES_FOR_CLIENT } from "../../queries/vip-workflow";
import ClientInfo from "./ClientInfo";
import "./dashboard.scss";
import DateTabs from "./DateTabs";
import PackageCard from "./PackageCard";
import SearchByEventNameInput from "./SearchByEventNameInput";
import { getUserEmail, isEmailConfirmed } from "../../utils/services";

const Dashboard = () => {
  const client = useApolloClient();

  const [showOrders, setShowOrders] = React.useState("upcoming");
  const [data, setData] = React.useState<any>({});
  const [eventNameSearch, setEventNameSearch] = React.useState("");

  const getClientPackages = async () => {
    const initialOrdersData = await client.query({
      query: GET_PACKAGES_FOR_CLIENT,
      variables: { email: getUserEmail(), showOrders, eventNameSearch },
      fetchPolicy: "cache-first",
    });

    setData(initialOrdersData.data);
  };

  React.useEffect(() => {
    if (isEmailConfirmed()) {
      getClientPackages();
    }
  }, [showOrders]);

  return (
    <Container className="mt-4" fluid>
      <Row>
        <Col sm="8">
          <SearchByEventNameInput
            getClientPackages={getClientPackages}
            setEventNameSearch={setEventNameSearch}
          />
          <DateTabs showOrders={showOrders} setShowOrders={setShowOrders} />
          <PackageCard showOrders={showOrders} clientPackages={data?.getClientPackages} />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
