import { useState } from 'react';
import { Modal, Spinner } from 'reactstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import ticketsIcon from "../../assets/img/icons/cards/tickets-icon.svg";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const TicketsPreviewModal = ({
  isModalOpened,
  setIsModalOpened,
  selectedFile,
}: any) => {
  const [numPages, setNumPages] = useState<null | number>(null);
  const [loading, setLoading] = useState(true);

  const isPdf = selectedFile && selectedFile.startsWith('data:application/pdf');

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setLoading(false);
  };

  const renderFile = () => {
    if (isPdf) {
      return (
        <>
          {loading && <Spinner />}
          <Document
            file={selectedFile}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(error) => console.error('Error loading PDF', error)}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </>
      );
    } else {
      return (
        <img
          src={selectedFile}
          style={{ maxWidth: '100%', maxHeight: '100%', display: 'block', margin: '0 auto' }}
          alt="Ticket Preview"
        />
      );
    }
  };

  return (
    <Modal
      isOpen={isModalOpened}
      toggle={() => setIsModalOpened(false)}
      contentClassName="border-radius bg-white"
      className="modal-dialog-centered modal-secondary"
      size="lg"
      fade={false}
    >
      <div className="modal-header">
        <div className="d-flex align-items-center">
          <img className="mr-2" src={ticketsIcon} alt="tickets-icon" />
          <span className="tickets-details-span">Ticket Details</span>
        </div>
        <div className="d-flex align-items-center">
          <button
          type="button"
          style={{ background: 'none', border: 'none', fontSize: '1.5rem', cursor: 'pointer' }}
          onClick={() => setIsModalOpened(false)}
          >
          <i className="fas fa-times" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="modal-body">
        {renderFile() || <Spinner />}
      </div>
    </Modal>
  );
};
