import { IRoutes } from "./utils/interfaces";

const routes: IRoutes = {
  RECIPIENT: [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: "fas fa-stream text-green",
    },
  ],
  BROKER: [
    {
      path: "/broker-dashboard",
      name: "Broker Dashboard",
      icon: "fas fa-stream text-red",
    },
  ],
};

export default routes;
