import { DatePicker } from "antd";
import moment from "moment";
import React from "react";
import {
  Button,
  ButtonDropdown,
  CardHeader,
  Col,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
const { RangePicker } = DatePicker;

const SORT_DATE_RANGE_LABELS = {
  saleDate: "Sale Date",
  shipBy: "Ship By",
  eventDate: "Event Date",
};

const DATE_LABELS = {
  [moment().subtract(7, "days").startOf("day") as any]: "Last 7 Days",
  [moment().subtract(1, "days").startOf("day") as any]: "Yesterday",
  [moment().endOf("date") as any]: "Today",
  [moment().add(1, "days").endOf("day") as any]: "Tomorrow",
  [moment().add(7, "days").endOf("day") as any]: "Next 7 Days",
};

const TableActions = ({
  currentTab,
  handleClearFilters,
  setIsFiltersModalOpen,
  sortDateRangeBy,
  setSortDateRangeBy,
  dateRange,
  setDateRange,
  handleApplyFilters,
}: any) => {
  const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);

  const handleRadioChange = (e: any) => {
    setSortDateRangeBy(e.target.value);
  };

  const handleDateRangeChange = (dateRange: any) => {
    setDateRange(dateRange);
  };

  return (
    <CardHeader className="border-0">
      <Row className="d-flex align-items-center">
        <Col xs="2">
          <h3 className="mb-0">{currentTab}</h3>
        </Col>
        <Col xs="10">
          <div className="d-flex justify-content-end align-items-center m-0">
            <ButtonDropdown
              className="mr-3 btn-round btn-icon d-flex"
              isOpen={isDropdownOpened}
              toggle={() => {
                setIsDropdownOpened((prev) => {
                  return !prev;
                });
              }}
            >
              <DropdownToggle
                style={{
                  color: dateRange ? "" : "#bfbfbf",
                  borderRadius: "2px",
                  border: "1px solid #d9d9d9",
                  background: "white",
                  fontWeight: "400",
                  padding: "8px",
                  width: "14rem",
                  textAlign: "left",
                  height: "2.5rem",
                }}
              >
                {!dateRange
                  ? "Date"
                  : `
                  ${
                    SORT_DATE_RANGE_LABELS[sortDateRangeBy as keyof typeof SORT_DATE_RANGE_LABELS]
                  } / ${
                      (dateRange && DATE_LABELS[dateRange[0]]) ||
                      DATE_LABELS[dateRange[1]] ||
                      "Custom"
                    }
                  `}
              </DropdownToggle>
              <DropdownMenu className="p-4" style={{ width: "18rem" }}>
                <div className="d-flex flex-column">
                  <h3>Sort By:</h3>
                  <div className="ml-2">
                    <input
                      type="radio"
                      name="saleDate"
                      id="saleDate"
                      value="saleDate"
                      checked={sortDateRangeBy === "saleDate"}
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="saleDate" className="ml-2">
                      Sale Date
                    </label>
                  </div>
                  <div className="ml-2">
                    <input
                      type="radio"
                      name="eventDate"
                      id="eventDate"
                      value="eventDate"
                      checked={sortDateRangeBy === "eventDate"}
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="eventDate" className="ml-2">
                      Event Date
                    </label>
                  </div>
                  <div className="ml-2">
                    <input
                      type="radio"
                      name="shipBy"
                      id="shipBy"
                      value="shipBy"
                      checked={sortDateRangeBy === "shipBy"}
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="shipBy" className="ml-2">
                      Ship By Date
                    </label>
                  </div>
                </div>
                <RangePicker
                  className="mt-2"
                  style={{ zIndex: "9999" }}
                  onChange={handleDateRangeChange}
                  getPopupContainer={(triggerNode: any) => {
                    return triggerNode.parentNode;
                  }}
                  value={dateRange}
                  ranges={{
                    "Last 7 Days": [
                      moment().subtract(7, "days").startOf("day"),
                      moment().endOf("date"),
                    ],
                    Yesterday: [
                      moment().subtract(1, "days").startOf("day"),
                      moment().endOf("date"),
                    ],
                    Today: [moment().startOf("day"), moment().endOf("date")],
                    Tomorrow: [moment().startOf("date"), moment().add(1, "days").endOf("day")],
                    "Next 7 Days": [moment().startOf("date"), moment().add(7, "days").endOf("day")],
                  }}
                  format="MM/DD/YYYY"
                />
                <div className="text-right">
                  <Button
                    color="primary"
                    size="sm"
                    className="mt-3"
                    onClick={() => {
                      handleApplyFilters();
                      setIsDropdownOpened(false);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </DropdownMenu>
            </ButtonDropdown>
            {/* {IS_EXPANDABLE_ROWS.includes(currentTab) ? (
              <>
                <Select
                  className="mr-3 w-10-rem"
                  value={typesToFilter}
                  mode="multiple"
                  options={TYPE_FILTER_OPTIONS}
                  onChange={handleTypeFilter}
                  placeholder="Type"
                  maxTagCount="responsive"
                  allowClear
                  size="large"
                />
                <Select
                  value={csrIdsToFilter}
                  mode="multiple"
                  options={[{ label: "Unassigned", value: 0 }, ...csrListState]}
                  onChange={handleCsrChange}
                  placeholder="CSR"
                  className="w-10-rem"
                  maxTagCount="responsive"
                  allowClear
                  size="large"
                />
              </>
            ) : null} */}
            <Button
              className="btn-round btn-icon d-flex align-items-center p-2 ml-3"
              color="primary"
              size="sm"
              onClick={(e) => {
                e.preventDefault();
                setIsFiltersModalOpen(true);
              }}
            >
              <span className="btn-inner--text">Open Filters</span>
            </Button>
            <Button
              className="btn-round btn-icon d-flex align-items-center p-2 ml-2"
              color="primary"
              size="sm"
              onClick={handleClearFilters}
            >
              <span className="btn-inner--text">Clear Filters</span>
            </Button>
          </div>
        </Col>
      </Row>
    </CardHeader>
  );
};

export default TableActions;
