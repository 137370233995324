import { useReactiveVar } from "@apollo/client";
import React from "react";
import { Card, Spinner, Table } from "reactstrap";
import { isTableDataLoading } from "../../cache/vars";
// import FiltersModal from '../Modal/FiltersModal';
// import PaginationComponent from '../Pagination/Pagination';
// import './Table.scss';
// import TableActions from './TableActions';
import TableBody from "./TableBody";
import TableHead from "./TableHead";
import "./Table.scss";
import TableActions from "./TableActions";
import PaginationComponent from "../../components/Pagination/Pagination";
import FiltersModal from "../../components/Modal/FiltersModal";

const TableLayout = ({
  filterState,
  setFilterState,
  sortBy,
  sortDirection,
  setSortBy,
  setSortDirection,
  tableConfig,
  orders,
  isQueryLoading,
  totalPageCount,
  totalPackagesCount,
  page,
  setPage,
  setItemsPerPage,
  itemsPerPage,
  handleApplyFilters,
  handleClearFilters,
  sortDateRangeBy,
  setSortDateRangeBy,
  dateRange,
  setDateRange,
}: any) => {
  const isLoading = useReactiveVar(isTableDataLoading);

  const [isTooltipOpen, setIsTooltipOpen] = React.useState("");
  const [isPopoverOpen, setIsPopoverOpen] = React.useState("");
  const [isFiltersModalOpen, setIsFiltersModalOpen] = React.useState(false);

  const handleSorting = (key: string) => {
    setSortBy(key);
    setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
  };

  return (
    <Card>
      <FiltersModal
        isFiltersModalOpen={isFiltersModalOpen}
        setIsFiltersModalOpen={setIsFiltersModalOpen}
        tableConfig={tableConfig}
        filterState={filterState}
        setFilterState={setFilterState}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFilters}
      />
      <TableActions
        handleClearFilters={handleClearFilters}
        setIsFiltersModalOpen={setIsFiltersModalOpen}
        sortDateRangeBy={sortDateRangeBy}
        setSortDateRangeBy={setSortDateRangeBy}
        dateRange={dateRange}
        setDateRange={setDateRange}
        handleApplyFilters={handleApplyFilters}
      />
      <div
        style={{
          maxHeight: "65vh",
          overflowY: "auto",
        }}
      >
        <Table className="Table" responsive>
          <TableHead
            tableConfig={tableConfig}
            setFilterState={setFilterState}
            handleSorting={handleSorting}
            sortBy={sortBy}
            sortDirection={sortDirection}
            filterState={filterState}
            handleApplyFilters={handleApplyFilters}
          />
          {isQueryLoading || isLoading ? (
            <tr>
              <td colSpan={17} className="p-3 text-center">
                <Spinner animation="border" variant="info" />
              </td>
            </tr>
          ) : null}
          {orders.length && !isQueryLoading && !isLoading ? (
            <TableBody orders={orders} tableConfig={tableConfig} />
          ) : !isQueryLoading && !isLoading ? (
            <tr>
              <td colSpan={17} className="p-3 text-center">
                <h2>Data Not Found</h2>
              </td>
            </tr>
          ) : null}
        </Table>
      </div>
      {!isQueryLoading && !isLoading && orders.length ? (
        <PaginationComponent
          page={page}
          setPage={setPage}
          totalPageCount={totalPageCount}
          totalPackagesCount={totalPackagesCount}
          setItemsPerPage={setItemsPerPage}
          itemsPerPage={itemsPerPage}
        />
      ) : null}
    </Card>
  );
};

export default TableLayout;
