import axios from 'axios';
import { useState, FC, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import copyIcon from "../../../assets/img/icons/cards/copy-icon.svg";

import "./cardGoogleMap.scss";
import { successMsg, errorMsg } from '../../../cache/vars';

type CardGoogleMapProps = {
  address?: string;
  className?: string;
  style?: React.CSSProperties;
};

const CardGoogleMap: FC<CardGoogleMapProps> = ({ address, style, className }) => {
  const [lat, setLat] = useState<number | null>(null);
  const [lng, setLng] = useState<number | null>(null);
  const [zip, setZip] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [state, setState] = useState<string | null>(null);
  
  const [link, setLink] = useState<string | null>(null);
  
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_KEY || '',
  });
  
  const copyToClipboard = () => {
    const text = [address && address, city && city, state && state, zip && zip].filter(Boolean).join(', ');
    navigator.clipboard.writeText(text || 'Address not found').then(
      () => {
        successMsg('Address copied to clipboard!');
      },
      (err) => {
        errorMsg('Error copying address to clipboard');
        console.error("Could not copy text: ", err);
      }
    );
  };
  
  const renderMap = () => {
    if (!lat || !lng) return null;

    if (!isLoaded) return null;
    
    return (
      <GoogleMap
        center={{ lat, lng }}
        zoom={15}
        mapContainerStyle={{ width: '100%', height: '400px' }}
      >
        <Marker position={{ lat, lng }} />
      </GoogleMap>
    );
  };
  
  useEffect(() => {
    const fetchCoordinates = async (address: string) => {
      try {
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_PLACES_KEY}`);
        if (response.data.results.length > 0) {
          
          const location = response.data.results[0].geometry.location;
          
          const addressComponents = response.data.results[0].address_components;

          addressComponents.forEach((component: any) => {
            if (component.types.includes('postal_code')) {
              setZip(component.long_name);
            }
            if (component.types.includes('administrative_area_level_1')) {
              setState(component.long_name);
            }
            if (component.types.includes('locality')) {
              setCity(component.long_name);
            }
          });
          
          setLat(location.lat);
          setLng(location.lng);
          setLink(`https://www.google.com/maps/search/?api=1&query=${location.lat}%2C${location.lng}&query_place_id=${response.data.results[0].place_id}`);
        } else {
          console.error('No results found');
        }
      } catch (error) {
        console.error('Error fetching coordinates', error);
      }
    };
    
    if (address) { 
      fetchCoordinates(address) 
    };  
  }, [address]);

  return (
    <div className={`google-map-wrapper ${className}`} style={style}>
      {address && 
        <div className="google-map-copy-address">
          <div className="google-map-copy-container" onClick={copyToClipboard}>
            <img loading="lazy" src={copyIcon} alt="copy-icon" className="google-map-copy-icon" />
            <span className="google-map-copy-text">
              Copy address
            </span>
          </div>
        </div>
      }
      <div className="google-map-content">
        <div className="google-map-directions">
          { renderMap() }
        </div>
      </div>
    </div>
  );
};

export default CardGoogleMap;
